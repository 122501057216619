<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="nodeToBind"
      :hide-required-asterisk="false"
      class="node-details-form"
    >
      <el-form-item prop="node_name" :label="__('Name')">
        <el-input
          v-model="nodeToBind.node_name"
          :placeholder="__('set voice node')"
        ></el-input>
      </el-form-item>
      <el-form-item
        class="form_label_top"
        :label="__('Text to Speech Voice')"
        prop="set_voice_node.data.tts_voice_id"
      >
        <tts-dropdown-detail
          :default_tts_id="nodeToBind.set_voice_node.data.tts_voice_id"
          @tts-changed="handleTtsSelected"
        ></tts-dropdown-detail>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import BaseNode from "./BaseNode";
import _ from "lodash";
import { NODE_TYPES } from "@/constants/nodes";
import { mapActions, mapState } from "vuex";
import TtsDropdownDetail from "@/views/build/content/prompts/components/TtsDropdownDetail";

export default {
  mixins: [BaseNode],
  components: {
    TtsDropdownDetail
  },
  data() {
    return {
      loadingVoices: false,
      rules: {
        set_voice_node: {
          data: {
            tts_voice_id: [
              {
                required: true,
                message: __("Text to Speech Voice cannot be empty"),
                trigger: "blur"
              }
            ]
          }
        }
      }
    };
  },
  computed: {
    ...mapState("tasks", {
      languages: state => state.languages
    }),

    /**
     * method to do extra checks to validate form, it cannot be handled by the element UI form validations
     * @returns {boolean}
     */
    formHasErrors() {
      return false;
    }
  },
  methods: {
    ...mapActions("tasks", {
      getLanguages: "getLanguages"
    }),

    async handleTtsSelected(tts_voice) {
      this.nodeToBind.set_voice_node.data.tts_voice_id = tts_voice;
    },

    initializeSetVoiceNode() {
      this.getLanguages();

      if (
        !this.nodeToBind.node_id ||
        _.isEmpty(this.nodeToBind.set_voice_node)
      ) {
        this.$set(this.nodeToBind, "set_voice_node", {});
        this.$set(this.nodeToBind.set_voice_node, "data", {});
        this.$set(this.nodeToBind.set_voice_node.data, "tts_voice_id", null);
        this.$set(this.nodeToBind, "node_type", NODE_TYPES.SET_VOICE.NODE_TYPE);
      }
    },

    cleanUpNodeToPrepareForSubmit() {
      const nodeToCleanUp = _.cloneDeep(this.nodeToBind);
      return nodeToCleanUp;
    },

    cleanUpNode() {
      if (!this.formHasErrors) {
        this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
        this.createOrEditNode();
      } else {
        this.toggleNodeSubmit(false);
      }
    }
  },
  created() {
    this.initializeSetVoiceNode();
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/node-palette.scss";
@import "~@/styles/node_common.scss";

.el-form-item:last-of-type {
  margin-bottom: 10px;
}

.text-input ::v-deep textarea {
  resize: none;
}
</style>
